<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © 2021 BETDED 888

      <span class="d-none d-sm-inline-block">, All rights Reserved</span> |
      ติดต่อ ADMIN ได้ที่ <a href="https://lin.ee/x3KjS4v">@BETDED888</a>
    </span>
  </p>
</template>

<script>
export default {}
</script>
